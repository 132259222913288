import React, { Component } from 'react';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { showError, showSuccess, subDomain, Button } from '../../../common/Common';
import { LoaderContents } from "../../layout/LoaderContents";
import { GetAllRoles } from '../../../services/UserServices';
import { GetAllAccessModules, GetAllAccessModulesBYRoleId, AddAccessModulesToRole } from '../../../services/AccessModulesServices';
import $ from 'jquery';
class ManagePermissions extends Component {
     constructor(props) {
        super(props);
        this.state = {
            Errors: {},
            AccessModulesMdl: [],
            RoleAccessModulesMdl:[],
            SaveLoaderStatus: false,
            ContentsLoaderStatus: false,
            RoleList: [],
            SelectedRoleId: 0,
            disabled: true,
            SubDomain: subDomain()
        }
    }
componentDidMount(){
    this._GetAllRoles(this.state.SubDomain.SubDomainId);
    this._GetAllAccessModules();
    }
changePermissions= (items,event) => {
    if (event.target.checked) {
            if (this.state.RoleAccessModulesMdl != 0) {
                const data = this.state.RoleAccessModulesMdl.filter(el => el.accessModuleId !== items.id);
                this.setState({ RoleAccessModulesMdl: data });
            }
            if (event.target.value === "CanView") {
                this.setState(prevState => ({
                    RoleAccessModulesMdl: [...prevState.RoleAccessModulesMdl, { "roleId": this.state.SelectedRoleId, "accessModuleId": items.id, "canView": true, "cantVieworModify": false, "canModify": false }]
                }))
            }
            if (event.target.value === "CanModify") {
                this.setState(prevState => ({
                    RoleAccessModulesMdl: [...prevState.RoleAccessModulesMdl, { "roleId": this.state.SelectedRoleId, "accessModuleId": items.id, "canView": false, "canModify": true, "cantVieworModify": false }]
                }))
            }
            if (event.target.value === "CantVieworModify") {
                this.setState(prevState => ({
                    RoleAccessModulesMdl: [...prevState.RoleAccessModulesMdl, { "roleId": this.state.SelectedRoleId, "accessModuleId": items.id, "canView": false, "canModify": false, "cantVieworModify": true }]
                }))
            }
           }
     }
_GetAllRoles = (subDomainId) => {
    GetAllRoles(subDomainId).then(res => {
            let response = res;
                if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ RoleList: response.data });      
                } else {
                    showError(response.message);
                }
            }
        });
    }
    _GetAllAccessModules = () => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllAccessModules().then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AccessModulesMdl: response.data });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }
    _GetAllAccessModulesBYRoleId = (RoleId) => {
        this.setState({ AccessModulesMdl: [], RoleAccessModulesMdl:[], SelectedRoleId: RoleId, disabled: false });
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllAccessModulesBYRoleId(RoleId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AccessModulesMdl: response.data });
                  if (response.data != null && response.data.length > 0) {
                         for (var i = 0; i < response.data.length; i++) {
                           if (response.data[i].roleAccessModulesMdl != null) {
                                this.setState(prevState => ({
                                    RoleAccessModulesMdl: [...prevState.RoleAccessModulesMdl, { "roleId": response.data[i].roleAccessModulesMdl.roleId, "accessModuleId": response.data[i].roleAccessModulesMdl.accessModuleId, "canView": response.data[i].roleAccessModulesMdl.canView, "canModify": response.data[i].roleAccessModulesMdl.canModify, "cantVieworModify": response.data[i].roleAccessModulesMdl.cantVieworModify }]
                                }));
                            }
                        }
                    }
                   } else {
                    showError(response.message);
                }
               this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }
    _AddAccessModulesToRole = () => {
        if (this.state.SelectedRoleId === 0) {
            $("#role").focus();
            showError("Please select role !");
            return false;
        }
        if (this.state.RoleAccessModulesMdl === null || this.state.RoleAccessModulesMdl.length === 0) {
            $("#role").focus();
            showError("Modules permission are empty !");
            return false;
        }
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        AddAccessModulesToRole(this.state.RoleAccessModulesMdl).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    showSuccess(response.message);
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }
    render() {
        const { RoleList, AccessModulesMdl, disabled } = this.state;
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <div className="row">
                            <div className="col-xl-8 col-xxl-8">
                                <h1 className="h3 mb-0 fw-normal"><strong>Manage Permissions</strong></h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border-light w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="card-header bg-white border-white p-2 d-flex justify-content-end">
                                            <select className="form-select text-search-newDesign w-50" id="role" defaultValue="null" onChange={() => this._GetAllAccessModulesBYRoleId(event.target.value)}>
                                                <option value="null" disabled>Select role</option>
                                                {RoleList.map((items, index) => (
                                                    <option key={index} value={items.id}>{items.name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0 permissions-table">
                                                <thead>
                                                    <tr>
                                                        <th rowSpan="2">Sno</th>
                                                        <th rowSpan="2">Modules</th>
                                                        <th rowSpan="2">URL</th>
                                                        <th colSpan="3" className="text-center">Permissions</th>
                                                    </tr>
                                                    <tr>
                                                        <th className="text-center">Can View</th>
                                                        <th className="text-center">Can Modify</th>
                                                        <th className="text-center">Can View/Modify</th>
                                                     </tr>
                                                </thead>
                                                <tbody>
                                                    {AccessModulesMdl.map((items, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{items.normalizedModuleName}</td>
                                                            <td>{items.moduleURL}</td>
                                                            <td className="text-center">
                                                                {items.roleAccessModulesMdl != null ?
                                                                    (
                                                                        items.roleAccessModulesMdl.accessModuleId === items.id &&
                                                                        (
                                                                            items.roleAccessModulesMdl.canView ?
                                                                                (
                                                                                     <div className="input-wrapper-radio radio-permission">
                                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} defaultChecked className="position-absolute top-50 translate-middle-y" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                         </label>
                                                                                       {/* <input className="form-check-input" name={`Role_[${items.id}][]`} defaultChecked type="radio" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />*/}
                                                                                    </div>
                                                                                   
                                                                                ) :
                                                                                 <div className="input-wrapper-radio radio-permission">
                                                                                    <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                    <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                         </label>
                                                                                   {/* <input className="form-check-input" name={`Role_[${items.id}][]`} type="radio" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />*/}
                                                                                    </div>
                                                                            )
                                                                    ) :
                                                                    <div className="input-wrapper-radio radio-permission">
                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                        </label>
                                                                        {/* <input className="form-check-input" name={`Role_[${items.id}][]`} type="radio" value="CanView" disabled={disabled} onChange={() => this.changePermissions(items, event)} />*/}
                                                                    </div>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {items.roleAccessModulesMdl != null ?
                                                                    (
                                                                        items.roleAccessModulesMdl.accessModuleId === items.id &&
                                                                            (
                                                                                items.roleAccessModulesMdl.canModify ?
                                                                                (
                                                                                    <div className="input-wrapper-radio radio-permission">
                                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} defaultChecked className="position-absolute top-50 translate-middle-y" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                         </label>
                                                                                    {/*<input className="form-check-input" name={`Role_[${items.id}][]`} defaultChecked type="radio" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items,event)} />*/}
                                                                                    </div>
                                                                                ) :
                                                                                 <div className="input-wrapper-radio radio-permission">
                                                                                    <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                    <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                         </label>
                                                                                    {/* <input className="form-check-input" name={`Role_[${items.id}][]`} type="radio" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items,event)} />*/}
                                                                                    </div> 
                                                                            )
                                                                    ) :
                                                                        <div className="input-wrapper-radio radio-permission">
                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                         </label>
                                                                        {/*<input className="form-check-input" name={`Role_[${items.id}][]`} type="radio" value="CanModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />*/}
                                                                         </div>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {items.roleAccessModulesMdl != null ?
                                                                    (
                                                                        items.roleAccessModulesMdl.accessModuleId === items.id &&
                                                                        (
                                                                            items.roleAccessModulesMdl.cantVieworModify ?
                                                                                (
                                                                                    <div className="input-wrapper-radio radio-permission">
                                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} defaultChecked className="position-absolute top-50 translate-middle-y" value="CantVieworModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                        </label>
                                                                                    </div>
    
                                                                                ) :
                                                                                <div className="input-wrapper-radio radio-permission">
                                                                                    <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CantVieworModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                                    <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                                        <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                                    </label>
                                                                                </div>
                                                                        )
                                                                    ) :
                                                                    <div className="input-wrapper-radio radio-permission">
                                                                        <input type="radio" id={`Role_${items.id}`} name={`Role_${items.id}`} className="position-absolute top-50 translate-middle-y" value="CantVieworModify" disabled={disabled} onChange={() => this.changePermissions(items, event)} />
                                                                        <label htmlFor={`Role_${items.id}`} className="p-3 ps-5">
                                                                            <svg viewBox="0 0 21 21"><polyline points="5 10.75 8.5 14.25 16 6"></polyline></svg>
                                                                        </label>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                            <div className="addPermission">
                                                <button type="button" id="addPermission" className="btn btn-newDesign btn-large" onClick={() => this._AddAccessModulesToRole() }>
                                                    <span className="label">Submit</span>
                                                </button>
                                            </div>
                                                { /*loader show befoure load contents*/}
                                            <div>
                                                 {this.state.ContentsLoaderStatus && <LoaderContents />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <SiteFooter /> {/*this call for site footer */}
                </div>
            </>
        );
    }
}
export default ManagePermissions;