import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, { Component } from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { DateFormatShort } from "../../../common/Setting";
import {LoaderContents} from "../../layout/LoaderContents";

class AccountActivity extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 25,
                PageNumber: 1
            },
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
        }
    }
    
    handleSearchData = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { GeneralSearch: event.target.value }));
    }
    
    handleShowChange = (event) => {
        this.setState(Object.assign(this.state.searchMdl, { LimitTo: event.target.value }));
    }
    render() {

        return (
            <>
                <div className="main-wrapper-newDesign account-overview w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">    
                        <h1 className="h3 mb-0 fw-normal"><strong>Account Activity</strong></h1>
                        <div className="row">
                            <div className="col-xl-12 col-xxl-12">
                                <div className="card flex-fill mt-4 border w-100 dashboard-card-newDesign">
                                    <div className="card-body">
                                        <div className="dashboard-options">
                                            <div className="card-header bg-white border-white p-2 dashboard-input-size">
                                                <input type="text" className="form-control text-search-newDesign" onChange={this.handleSearchData} id="search" placeholder="Search by Description" />
                                                {this.state.SaveLoaderStatus &&
                                                    <span style={{ position: "absolute", right: "222px", top: "27px" }} className="fa fa-spinner fa-spin"></span>
                                                }
                                            </div>
            
                                            <div className="card-header bg-white border-white p-2 custom-select">
                                                <select className="form-select text-search-newDesign" defaultValue={this.state.searchMdl.LimitTo} onChange={this.handleShowChange}>
                                                    <option value={this.state.searchMdl.LimitTo} disabled>Show</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-100 table-responsive">
                                            <table className="table insured-table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="Details-table-header">Date</th>
                                                        <th className="Details-table-header">Amount</th>
                                                        <th className="Details-table-header">Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="Details-table-header">{DateFormatShort(new Date().toDateString())}</td>
                                                        <td className="Details-table-header">$4,400.00</td>
                                                        <td className="Details-table-header">ACCOUNT BALANCE (00905152)</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">{DateFormatShort(new Date().toDateString())}</td>
                                                        <td className="Details-table-header">$960.00</td>
                                                        <td className="Details-table-header">ACCOUNT BALANCE (00905152)</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">5/24/2023</td>
                                                        <td className="Details-table-header">$2,0537.35</td>
                                                        <td className="Details-table-header">NEW ACCOUNT</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">6/22/2023</td>
                                                        <td className="Details-table-header">$-203.76</td>
                                                        <td className="Details-table-header">CREDIT CARD PAYMENT (00436841)</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">7/21/2023</td>
                                                        <td className="Details-table-header">$-203.76</td>
                                                        <td className="Details-table-header">CREDIT CARD PAYMENT (00436841)</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">7/29/2024</td>
                                                        <td className="Details-table-header">$-10.19</td>
                                                        <td className="Details-table-header">ADJUST LATE CHARGE</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="Details-table-header">7/29/2024</td>
                                                        <td className="Details-table-header">$1000.00</td>
                                                        <td className="Details-table-header">POLICY ENDORSEMENT</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SiteFooter />
                </div>
            </>
        );
    }
}
export default AccountActivity;