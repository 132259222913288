import { showError } from '../common/Common';
import { authHeader } from '../common/Common';

//get GetAll Partners
async function GetAllPartners(searchMdl) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: JSON.stringify(searchMdl)
    };
    const GetResponse = await fetch('/api/partner/GetAllPartners', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get PartnerById
async function GetPartnerById(partnerId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/partner/GetPartnerId/' + partnerId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//add new partner
async function AddPartner(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: formData
    };
    const GetResponse = await fetch('/api/partner/AddPartner', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//update new partner
async function UpdatePartner(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${authHeader().Authorization}` },
        body: formData
    };
    const GetResponse = await fetch('/api/partner/UpdatePartner', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//delete a partner
async function DeletePartnerById(partnerId) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${authHeader().Authorization}` },
    };
    const GetResponse = await fetch('/api/partner/DeletePartnerById/' + partnerId, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get GetAll Partners by Name
async function GetAllPartnersByName(name) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/partner/GetAllPartnersByName/?name=' + name, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}

//get GetAll Partners by Url
async function GetAllPartnersByUrl(url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    const GetResponse = await fetch('/api/partner/GetAllPartnersByUrl/?url=' + url, requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


//get GetAll Partners by Url
async function GetAllPaymentProcess() {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authHeader().Authorization}` }
    };
    const GetResponse = await fetch('/api/partner/GetAllPaymentProcess', requestOptions)
        .then((response) => {
            return response.json();
        });
    return GetResponse;
}


export { GetAllPartners, AddPartner, UpdatePartner, GetPartnerById, DeletePartnerById, GetAllPartnersByName, GetAllPartnersByUrl, GetAllPaymentProcess };

    