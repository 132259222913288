import $ from 'jquery';
import validator from 'validator';
import ACHForm from '../Common/ACHForm';
import React, {Component, Fragment} from 'react';
import { SiteFooter } from "../../layout/SiteFooter";
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import {LoaderContents} from "../../layout/LoaderContents";
import { GetAllAISAccountInformation } from '../../../services/AISServices';
import {Button, showError, showSuccess, subDomain} from "../../../common/Common";
import ReactPaginate from "react-paginate";
import {ChangeAddress, ChangePassword, GetUserProfile} from "../../../services/UserServices";
import {
    GetAllMethodofPayments,
    GetPaymentProfile,
    GetStripeCustomer,
    UpsertPaymentProfile,
    GetAllInsuredPoliciesByUserId,
    GetAllInsuredPoliciesByPaymentId
} from "../../../services/PaymentServices";
import {PaymentTypeList} from "../../../common/Enum";
import {DeleteCustomerPaymentMethod} from "../../../services/PaymentAPIServices";
import {useSwipeable} from "react-swipeable";
import {CustomCurrencyFormat, DateFormat, DateFormatShort} from "../../../common/Setting";
import {NavLink} from "react-router-dom";
import Select from "react-select";

class AISSupportAccountOverview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchMdl: {
                GeneralSearch: null,
                LimitTo: 10,
                PageNumber: 1
            },
            PageCount: 0,
            ContentsLoaderStatus: false,
            PageLoaderStatus: false,
            AISAccounts: [],
            SubDomain: subDomain(),
            AISAccountNumber: props.location.state ? props.location.state.param : 0,
            currentPolicy: {},
            currentPolicyIndex: 0,
            AllInsuredPolicies: [],
            AccountDetails: {},
            AllMethodOfPayments:[],
            Errors: {},
            UserProfileMdl:
            {
                address: null
            },
            selectedBillingAccount: null
        }
    }
    
    componentDidMount() {
        this.setState({ PageLoaderStatus: true });
        this._GetAllAISAccountInformation();
        this.handleAnimationEnd = (e) => {
          if (e.animationName === 'fade-in') {
            e.target.classList.remove('fade-in-animation');
          }
        };
        
        document.addEventListener('animationend', this.handleAnimationEnd);
    };
    
     _GetAllAISAccountInformation() {
        GetAllAISAccountInformation().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        //this.setState({ PageCount: Math.ceil((response.data.totalRecord / this.state.searchMdl.LimitTo)) });
                        this.setState({ AISAccounts: response.data });
                        this.setState({ PageLoaderStatus: false });
                        const firstOption = {
                            value: response.data[0].userId,
                            label: response.data[0].insuredName,
                            aisAccountNumber: response.data[0].billingAccountNumber,
                        };
                        this.setState({ selectedOption: firstOption });
                        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, response.data[0].userId)
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }

    // get user profile
    _GetUserProfile = () => {
        this.setState({ LoaderStatus: true });
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        GetStripeCustomer(response.data.userInfoMdl.userId).then(data => {
                            this.setState({ CustomerDetailMdl: data });

                            if (this.state.CustomerDetailMdl.cardDetails !== null && this.state.CustomerDetailMdl.cardDetails !== "") {
                                this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: 2 }));
                                $("#radio-CreditCard").prop("checked", true);
                                $("#radio-ACH").prop("checked", false);
                                $("#radio-CreditCard").prop("disabled", true);
                                $("#radio-ACH").prop("disabled", true);
                            }
                            else if (this.state.CustomerDetailMdl.usBankDetails !== null && this.state.CustomerDetailMdl.usBankDetails !== "") {
                                this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: 1 }));
                                $("#radio-CreditCard").prop("checked", false);
                                $("#radio-ACH").prop("checked", true);
                                $("#radio-CreditCard").prop("disabled", true);
                                $("#radio-ACH").prop("disabled", true);
                            }
                            else if (this.state.CustomerDetailMdl.usBankDetails === "" && this.state.CustomerDetailMdl.cardDetails === "") {
                                if (this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.ACH) {
                                    $("#radio-ACH").prop("checked", true);
                                    $("#radio-CreditCard").prop("checked", false);
                                }
                                if (this.state.PaymentProfileMdl.paymentTypeId === PaymentTypeList.CreditCard) {
                                    $("#radio-CreditCard").prop("checked", true);
                                    $("#radio-ACH").prop("checked", false);
                                }

                                $("#radio-CreditCard").prop("disabled", false);
                                $("#radio-ACH").prop("disabled", false);
                            }
                            else {
                                $("#radio-CreditCard").prop("checked", false);
                                $("#radio-ACH").prop("checked", false);
                            }
                        });
                        this.setState({ PersonalInformation: response.data.userInfoMdl });
                        this.setState({ PersonalInformation: { ...this.state.PersonalInformation, email: response.data.email } });
                        this.setState({ UserProfileMdl: response.data.userInfoMdl });
                        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, response.data.id)
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }

    _DeletePaymentMethod = (CusId) => {
        this.setState({ DeleteLoader: true }); // show loader
        DeleteCustomerPaymentMethod(CusId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ isOutAutoPay: true });
                    this._GetUserProfile();
                    window.$('#confirmdeletemdl').modal('hide');
                    showSuccess(response.message);
                }
                else {
                    showError(response.message);
                }
                this.setState({ DeleteLoader: false }); // hide loader
            }
        });

    }

    GetAllInsuredPoliciesByUserId = (searchMdl, userId) => {
        GetAllInsuredPoliciesByUserId(searchMdl, userId).then(res => {
            let response = res;
            if (response.status === true) {
                this.setState({ AllInsuredPolicies: response.data.data, Pagecount: response.data.data.length });
                this.GetAllInsuredPoliciesByPaymentId(response.data.data[0].paymentId);
                this._GetAllMethodofPayments(response.data.data[0].paymentId, response.data.data[0].emailAddress);
            } else {
                showError(response.message);
            }
            this.setState({ LoaderStatus: false });
        });
    }

    GetAllInsuredPoliciesByPaymentId = (paymentId) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllInsuredPoliciesByPaymentId(paymentId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AccountDetails: response.data[0] });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false }); // hide loader after load data
            }
        });
    }

    _GetAllMethodofPayments = (paymentId, emailAddress) => {
        this.setState({ ContentsLoaderStatus: true }); // show loader befour load data
        GetAllMethodofPayments(paymentId, emailAddress).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ AllMethodOfPayments: response.data });
                    const totalAmountPaid = (response.data).reduce((total, payment) => {
                        return total + parseFloat(payment.amountPaid);
                    }, 0);

                    this.setState({ TotalAmountPaid: totalAmountPaid });
                    var myDate = new Date(response.data[0].datePaid);
                    var result = new Date(myDate.setMonth(myDate.getMonth() + 1));
                    var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                    var t = (month[result.getMonth()] +
                        " " + result.getDate() +
                        ", " + result.getFullYear());
                    this.setState({ nextDueDate: t });
                } else {
                    showError(response.message);
                }
                this.setState({ ContentsLoaderStatus: false, PageLoaderStatus: false }); // hide loader after load data
            }
        });
    }
    
    _ChangePassword_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangePassword();
        };
    };
    _ChangeAddress_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangeAddress();
        };
    };
    //form submit on enter key down (change payment method)
    _UpsertPaymentProfile_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._UpsertPaymentProfile();
        };
    };
    _ChangePassword = () => {
        let ChangePasswordMdl = {
            currentPassword: this.refs.currentPassword.value,
            newPassword: this.refs.newPassword.value,
            confirmPassword: this.refs.confirmPassword.value,
        }
        let status = this.ChangePasswordValidation(ChangePasswordMdl);
        if (status) {
            this.setState({ ChangePassLoaderStatus: true }); // show loader befour  change pass response form server
            ChangePassword(ChangePasswordMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseMdl').click();
                        showSuccess(response.message);
                        //document.getElementById("frmchangepassword").reset();
                        $("#frmchangepassword").trigger("reset");
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangePassLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    // validtion for change password validation
    ChangePasswordValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.currentPassword === null || mdl.currentPassword.trim() === "" || mdl.currentPassword === undefined) {
            errors['IsEmpty_currentPassword'] = true;
            errors['currentPasswordMsg'] = "Required fields : Current password";
            count++;
        }
        if (mdl.newPassword === null || mdl.newPassword.trim() === "" || mdl.newPassword === undefined) {
            errors['IsEmpty_newPassword'] = true;
            errors['newPasswordMsg'] = "Required fields : New password";
            count++;
        }
        else {
            if (!validator.isLength(mdl.newPassword, 8)) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (mdl.confirmPassword === null || mdl.confirmPassword === undefined || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required fields : Confirm password";
            count++;
        }
        else {
            if (!validator.equals(mdl.newPassword, mdl.confirmPassword)) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    // this is for change addredd
    _ChangeAddress = () => {
        let changeAddressmdl = {
            Address: this.refs.address.value
        }

        let status = this.ChangeAddressValidation(changeAddressmdl);
        if (status) {
            this.setState({ ChangeAddressLoaderStatus: true }); // show loader befour  change address response form server
            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: this.refs.address.value })));
            ChangeAddress(this.state.UserProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseAddressMdl').click();
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangeAddressLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    //validtion for address validtion 
    ChangeAddressValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.Address === null || mdl.Address === undefined || mdl.Address.trim() === "") {
            errors['IsEmpty_Address'] = true;
            errors['AddressMsg'] = "Required fields : Address";
            count++;
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //get payment profile
    _GetPaymentProfile = () => {
        this.setState({ LoaderStatus: true });
        this._GetUserProfile();
        GetPaymentProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data !== null) {
                        this.setState({ PaymentProfileMdl: response.data });
                        if (response.data.paymentTypeId === PaymentTypeList.ACH) {
                            $("#radio-ACH").prop("checked", true);
                            $("#radio-CreditCard").prop("checked", false);
                        }
                        if (response.data.paymentTypeId === PaymentTypeList.CreditCard) {
                            $("#radio-CreditCard").prop("checked", true);
                            $("#radio-ACH").prop("checked", false);
                        }
                    }
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    PaymentInfoValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.paymentTypeId === PaymentTypeList.ACH) {
            if (mdl.achAccountHolderName === null || mdl.achAccountHolderName.trim() === "" || mdl.achAccountHolderName === undefined) {
                errors['IsEmpty_achAccountHolderName'] = true;
                errors['achAccountHolderNameMsg'] = "Required field: Account Holder Name";
                count++;
            }
            if (mdl.achRoutingNumber === null || mdl.achRoutingNumber.trim() === "" || mdl.achRoutingNumber === undefined) {
                errors['IsEmpty_achRoutingNumber'] = true;
                errors['achRoutingNumberMsg'] = "Required field: Routing Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achRoutingNumber)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.achRoutingNumber, 9, 9)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Should contain at least 9 digit";
                    count++;
                }
            }
            if (mdl.achAccountNumber === null || mdl.achAccountNumber.trim() === "" || mdl.achAccountNumber === undefined) {
                errors['IsEmpty_achAccountNumber'] = true;
                errors['achAccountNumberMsg'] = "Required field: Account Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achAccountNumber)) {
                    errors['IsEmpty_achAccountNumber'] = true;
                    errors['achAccountNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
        }
        if (mdl.paymentTypeId === PaymentTypeList.CreditCard) {
            if (mdl.creditCardHolderName === null || mdl.creditCardHolderName.trim() === "" || mdl.creditCardHolderName === undefined) {
                errors['IsEmpty_creditCardHolderName'] = true;
                errors['creditCardHolderNameMsg'] = "Required field: Card Holder Name";
                count++;
            }
            if (mdl.creditCardNumber === null || mdl.creditCardNumber.trim() === "" || mdl.creditCardNumber === undefined) {
                errors['IsEmpty_creditCardNumber'] = true;
                errors['creditCardNumberMsg'] = "Required field: Card Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardNumber)) {
                    errors['IsEmpty_creditCardNumber'] = true;
                    errors['creditCardNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
            if (mdl.creditCardExpiryMonth === null || mdl.creditCardExpiryMonth.trim() === "" || mdl.creditCardExpiryMonth === undefined) {
                errors['IsEmpty_creditCardExpiryMonth'] = true;
                errors['creditCardExpiryMonthMsg'] = "Required field: Month";
                count++;
            }
            if (mdl.creditCardExpiryYear === null || mdl.creditCardExpiryYear.trim() === "" || mdl.creditCardExpiryYear === undefined) {
                errors['IsEmpty_creditCardExpiryYear'] = true;
                errors['creditCardExpiryYearMsg'] = "Required field: Year";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardExpiryYear)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid contain : year";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardExpiryYear, 4, 4)) {
                    errors['IsEmpty_creditCardExpiryYear'] = true;
                    errors['creditCardExpiryYearMsg'] = "Invalid year format";
                    count++;
                }
            }
            if (mdl.creditCardCVV === null || mdl.creditCardCVV.trim() === "" || mdl.creditCardCVV === undefined) {
                errors['IsEmpty_creditCardCVV'] = true;
                errors['creditCardCVVMsg'] = "Required field: CVV";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.creditCardCVV)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.creditCardCVV, 3, 4)) {
                    errors['IsEmpty_creditCardCVV'] = true;
                    errors['creditCardCVVMsg'] = "Should contain at least 3 or 4 digit";
                    count++;
                }
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //change payment method
    _UpsertPaymentProfile = () => {
        this.setState({ LoaderStatus: true }); // show loader
        UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ PaymentProfileMdl: response.data });
                    window.$('#modalChangePaymentMethod').modal('hide');
                    showSuccess(response.message);
                    window.$('#modalChangeAccountInfo').modal('show');
                }
                else {
                    showError(response.message);
                }
                this.setState({ LoaderStatus: false }); // hide loader
            }
        });
    }
    // change payment info
    _UpsertPaymentInfo = () => {
        let status = this.PaymentInfoValidation(this.state.PaymentProfileMdl);
        if (status) {
            this.setState({ LoaderStatus: true }); // show loader
            UpsertPaymentProfile(this.state.PaymentProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        this.setState({ PaymentProfileMdl: response.data });
                        window.$('#modalChangeAccountInfo').modal('hide');
                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }

    GetFullAddress = () => {
        return (this.state.PersonalInformation.address) ? this.state.PersonalInformation.address + ', ' + this.state.PersonalInformation.city + ', ' + this.state.PersonalInformation.state + ' ' + this.state.PersonalInformation.zip : ''
    }
    //for change payment method
    handleChangePaymentMethod = (event) => {
        if (this.state.CustomerDetailMdl.usBankDetails != null && this.state.CustomerDetailMdl.cardDetails != null) {
            let PaymentType = parseInt(event.target.value);
            this.setState(Object.assign(this.state.PaymentProfileMdl, { paymentTypeId: PaymentType }));
        }
        let dropDown = document.getElementById('payment-method-dd');
        if (event.target.value === "1") {
            dropDown.src = './assets/Content/images/cc-small.png';
            document.getElementById('cc-form').classList.remove('d-none');
            document.getElementById('ach-form').classList.add('d-none');
        }
        else {
            dropDown.src = './assets/Content/images/dollar-sign-small.png';
            document.getElementById('cc-form').classList.add('d-none');
            document.getElementById('ach-form').classList.remove('d-none');
        }
    }
    //for change payment info
    handleChangePaymentInfo = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState(Object.assign(this.state.PaymentProfileMdl, { [name]: value }));
    }

    //start funstions for pagination
    //handlePageClick = (data) => {
    //    let PageNumber = data.selected + 1;
    //    this.setState(Object.assign(this.state.searchMdl, { PageNumber: PageNumber }));
    //    this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, this.state.userId);
    //}
    handlePageClick = (data) => {
        const selectedPage = data.selected;

        // Check for boundary conditions
        if (selectedPage < 0 || selectedPage >= this.state.AllInsuredPolicies.length) {
            return;
        }

        const policyIndex = selectedPage;
        const PaymentId = this.state.AllInsuredPolicies[policyIndex].paymentId;
        const Email = this.state.AllInsuredPolicies[policyIndex].emailAddress;
        this.setState({ currentPolicy: this.state.AllInsuredPolicies[policyIndex] });
        this.setState({ paymentId: PaymentId });
        this.setState({
            currentPolicyIndex: policyIndex,
            searchMdl: {
                PageNumber: selectedPage + 1
            }
        });
        this.GetAllInsuredPoliciesByPaymentId(PaymentId);

        this._GetAllMethodofPayments(PaymentId, Email);
        this.transitionPage()
    };

    
    SwipeableWrapper = (props) => {
        const handlers = useSwipeable({
            onSwipedLeft: () => {
                let data = {
                     selected: (this.state.searchMdl.PageNumber - 1) + 1
                }
                if (data.selected < this.state.AllInsuredPolicies.length)
                    this.handlePageClick(data)
            },
            onSwipedRight: () => {
                let data = {
                     selected: (this.state.searchMdl.PageNumber - 1) - 1
                }
                if (data.selected >= 0)
                    this.handlePageClick(data)
            },
            preventDefaultTouchmoveEvent: true,
        });

        return (
            <div {...handlers} style={{ touchAction: 'pan-y' }}>
                {props.children}
            </div>
        );
    };
    
    transitionPage = () => {
        let element = document.getElementById("transition");
        // element.classList.add("visibility-transition-hide");
        // element.classList.remove("visibility-transition-hide");
        element.classList.add("fade-in-animation");
    }
    CheckIsDBPaid = (Policy) => {
        const prefix = "AISDB";
        const isAISDB = Policy.policyNumber && typeof Policy.policyNumber === 'string' &&
            Policy.policyNumber.slice(0, prefix.length) === prefix;
        return isAISDB;
    }
    IsOutAutoPay = (event) => {
        let element = document.getElementById("autopay-table");
        if (event.target.checked) {
            this.setState({ isOutAutoPay: true });
            element.classList.remove("disabled");
        }
        else {
            this.setState({ isOutAutoPay: false });
            element.classList.add("disabled");
        }
    }
    
    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        const billingAccountNumber = selectedOption.aisAccountNumber;
        const userId = selectedOption.value;
        
        this.ChangeSelectedInsured(userId);
    };
    
    ChangeSelectedInsured = (userId) => {
        this.GetAllInsuredPoliciesByUserId(this.state.searchMdl, userId);
    }
    
    render() {
        //const modalLink = (this.state.CustomerDetailMdl.usBankDetails || this.state.CustomerDetailMdl.cardDetails) ? "#modalChangeAccountInfo" : "#modalChangePaymentMethod";
        let url = subDomain().SubDomain === 'Xgen' ? "" : "/" + subDomain().SubDomain;
        const { AllInsuredPolicies, currentPolicyIndex, Pagecount, searchMdl, PageLoaderStatus } = this.state;
        const currentPolicy = AllInsuredPolicies[currentPolicyIndex] || {};
        const isAISDB = this.CheckIsDBPaid(currentPolicy);
        const lastIndex = this.state.AllMethodOfPayments.length - 1;
        const options = this.state.AISAccounts.map((items) => ({
            value: items.userId,
            label: items.insuredName,
            aisBillingAccountNumber: items.billingAccountNumber
        }));
        return (
            <>
           {PageLoaderStatus && <LoaderContents />}
           {!PageLoaderStatus &&
                <div className="main-wrapper-newDesign account-overview">
                    <InternalLayoutHeader />
                    {/*<div className="d-flex paginate-div">*/}
                    {/*    <ReactPaginate*/}
                    {/*        previousLabel={"Previous"}*/}
                    {/*        nextLabel={"Next"}*/}
                    {/*        onPageChange={this.handlePageClick}*/}
                    {/*        containerClassName={"pagination justify-content-start"}*/}
                    {/*        pageCount={Pagecount ?? 0}*/}
                    {/*        pageClassName={"page-item"}*/}
                    {/*        pageLinkClassName={"page-link"}*/}
                    {/*        previousClassName={"page-item"}*/}
                    {/*        nextClassName={"page-item"}*/}
                    {/*        nextLinkClassName={"page-link"}*/}
                    {/*        breakClassName={"page-item"}*/}
                    {/*        breakLinkClassName={"page-link"}*/}
                    {/*        activeClassName={"active"}*/}
                    {/*        disabledLinkClassName={"disabled"}*/}
                    {/*    />*/}
                    {/*    <div className="align-items-end">*/}
                    {/*        Page {searchMdl.PageNumber} of {Pagecount}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <Fragment>
                        <div className="content-main p-lg-4 p-3">
                            <h1 className="h3 mb-0 fw-normal"><strong>Account Overview</strong></h1>
                                <Select className="form-control input-newDesign input-wrapper-payment" 
                                    options={options}
                                    onChange={this.handleChange}
                                    isSearchable
                                    value={this.state.selectedOption}>
                                </Select>

                            <div id="transition" className="mt-4 container-overview account-overview position-relative container">
                                <this.SwipeableWrapper>
                                    <div className="d-flex justify-content-between paginate-div pagination-overview">
                                        <button
                                            className="page-link page-item"
                                            onClick={() => this.handlePageClick({ selected: this.state.currentPolicyIndex - 1 })}
                                            disabled={this.state.currentPolicyIndex === 0}
                                        >
                                            Previous
                                        </button>
                                        <button
                                            className="page-link page-item"
                                            onClick={() => this.handlePageClick({ selected: this.state.currentPolicyIndex + 1 })}
                                            disabled={this.state.currentPolicyIndex === this.state.AllInsuredPolicies.length - 1}
                                        >
                                            Next
                                        </button>
                                    </div>

                                    <div className="row">
                                        <div className="grid-container-newDesign-left col">
                                            <div className="overview-card account-details">
                                                <div className="account-info">
                                                    <div>
                                                        <p className="label-overview-newDesign">Account Number</p>
                                                        <p className="value">{currentPolicy.billingAccountNumber}</p>
                                                    </div>
                                                    <div className='account-detail'>
                                                        <div className="account-detail-labels">
                                                            <p className="table-label">Policy Type</p>
                                                            <p className="table-label">Premium Amount</p>
                                                            <p className="table-label">Account Balance</p>
                                                                <p className="table-label">{isAISDB ? "Installment Fee" : "APR" }</p>
                                                        </div>
                                                        <div className="account-detail-info">
                                                            <p className="table-data big-label">{"General Liability" || "\u00A0" }</p>
                                                            <p className="table-data small-label">{"GL" || "\u00A0" }</p>
                                                            <p className="table-data">{CustomCurrencyFormat(this.state.AccountDetails.premimumAmount)}</p>
                                                            <p className="table-data">{CustomCurrencyFormat(currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</p>
                                                            <p className="table-data">{isAISDB ? "$10.00" : "$17.66"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overview-card agent-info">
                                                <p className="label-lower">Agent's Information</p>
                                                <hr />
                                                    <p className="agent-header">Name <span className="value">RT Connector</span></p>
                                                    <p className="agent-header">Phone <span className="value">(464) 984-5623</span></p>
                                                    <p className="agent-header">Email <span className="value">rtconnector@mailinator.com</span></p>
                                                    <p className="agent-header">Address <span className="value">180 N. Stetson Avenue, Suite 4600, Chicago, IL 60601</span></p>
                                            </div>
                                            <div className="overview-card account-options">
                                                <p className="label-lower">Account Options</p>
                                                <hr />
                                                <div></div>
                                                <div></div>
                                                <div className="option-btn first-button">
                                                    <a className="icon-svg-overview">
                                                        <img src="./assets/Content/images/key.png" alt="Change Password"></img>
                                                    </a>
                                                    <div className="btn-content-newDesign">Change Password</div>
                                                </div>
                                                <div className="option-btn">
                                                    <a className="icon-svg-overview">
                                                        <img src="./assets/Content/images/cc.png" alt="Update Payment Method"></img>
                                                    </a>
                                                    <div className="btn-content-newDesign">Update Autopay Information</div>
                                                </div>
                                                <div className="option-btn">
                                                    <a href="#modalPendingPayment" className="icon-svg-overview" data-bs-toggle="modal">
                                                        <img src="./assets/Content/images/coins.png"></img>
                                                    </a>
                                                    <div className="btn-content-newDesign">Pending Payments</div>
                                                </div>
                                                <div className="option-btn">
                                                    <a href="#modalPolicyInformation" className="icon-svg-overview" data-bs-toggle="modal">
                                                        <img src="./assets/Content/images/map-pin.png" alt="Policy Information"></img>
                                                    </a>
                                                    <div className="btn-content-newDesign">Policy Information</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid-container-newDesign-right col-lg">
                                            <div className="overview-card payment-amount d-flex justify-content-start">
                                                <div className="payment-amount-upper">
                                                    <p className="label-overview-newDesign">Next Payment Amount</p>
                                                    <span className="payment-value">${currentPolicy.minimumPaymentDue}</span>
                                                </div>
                                                <hr />
                                                <div className="payment-info">
                                                    <div className="payment-info-top">
                                                        <p className="bold">Next Payment Due: </p>
                                                        <p className="payment-card-value">{this.state.nextDueDate}</p>
                                                    </div>
                                                    <div className="payment-info-bottom">
                                                        <p className="bold">Payments Remaining: </p>
                                                        <p className="payment-card-value">{this.state.AccountDetails.remainingMonths}</p>
                                                    </div>
                                                </div>
    
                                            </div>
                                            <div className="overview-card payment-history">
                                                <p className="fw-bold">Payment History</p>
                                                <hr />
                                                <div className="payment-items">
                                                    {this.state.AllMethodOfPayments.map((items1) => (
                                                        <div className="payment-item" key={items1.id}>
                                                            <div className="payment-description">
                                                                <p>{items1.methodofPayment} Payment</p>
                                                                <p className="payment-date">{DateFormat(items1.datePaid)}</p>
                                                            </div>
                                                            <p className="payment-amount">{CustomCurrencyFormat(items1.amountPaid)}</p>
                                                        </div>
                                                    ))}
                                                </div>
    
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card dashboard-card-newDesign mt-4">
                                        <div className="row">
                                            <div className="col-lg-5 col-sm-5 col-12 ">
    
                                            </div>
                                        </div>
                                        <div className="card-body details-card">
                                            <div className="w-100 table-responsive">
                                                <table className="table insured-table mb-0">
                                                    <thead>
                                                        <h1 className="Details-table-header">Details</h1>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="details-table-label">Late Charge</td>
                                                            <td className="details-table-data">$0.00</td>
    
                                                            <td className="details-table-label">Return Check Charge</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Cancellation Charge</td>
                                                            <td className="details-table-data">$0.00</td>
    
                                                            <td className="details-table-label">Finance Charge</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Payoff Amount</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</td>
    
                                                            <td className="details-table-label">Refund and Adjustments</td>
                                                            <td className="details-table-data">$0.00</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Payments Made</td>
                                                            <td className="details-table-data">{10 - this.state.AccountDetails.remainingMonths}</td>
    
                                                            <td className="details-table-label">Payments Left</td>
                                                            <td className="details-table-data">{this.state.AccountDetails.remainingMonths}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Down Payment</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(currentPolicy.downPayment)}</td>
    
                                                            <td className="details-table-label">{isAISDB ? "Installment Fee" : "Annual Percentage Rate"}</td>
                                                            <td className="details-table-data">{isAISDB ? "$10.00" : "$17.66"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">Down Payment Percentage</td>
                                                            <td className="details-table-data">{((100 * (currentPolicy.downPayment)) / this.state.AccountDetails.premimumAmount).toFixed(2)}%</td>
    
                                                            <td className="details-table-label">Original Amount Financed</td>
                                                            <td className="details-table-data">{CustomCurrencyFormat(this.state.AccountDetails.premimumAmount - currentPolicy.downPayment)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="details-table-label">First Payment Date</td>
                                                                <td className="details-table-data">{this.state.AllMethodOfPayments[0] && this.state.AllMethodOfPayments[lastIndex].datePaid}</td>
                                                            
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </this.SwipeableWrapper>
                                { /*model for change address*/}
                                <div className="modal fade" tabIndex="-1" id="modalChangeAddress" aria-labelledby="changeAddress" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header justify-content-center pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/map-pin-glow-cropped.png"></img>
                                                <h5 className="modal-title">Change Address</h5>
                                            </div>
                                            <div className="modal-body">
                                                <div className="pt20px">
                                                    { /*loader show befoure load contents*/}
                                                    {this.state.ChangeAddressLoaderStatus && <LoaderContents />}
                                                </div>
                                                <form className="w-75 m-auto" id="frmchangeaddress" onKeyDown={this._ChangeAddress_EnterButton}>
                                                    {/*<div className="form-floating input-wrapper-payment mb-3">*/}
                                                    {/*    */}{/*<input type="text" className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')} ref="Address" id="autocomplete_address" aria-describedby="Address" placeholder="Enter your address *" name="address" defaultValue={this.state.UserProfileMdl.address ?? ""} required />*/}
                                                    {/*    <GetPlace*/}
                                                    {/*        className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')}*/}
                                                    {/*        id="address"*/}
                                                    {/*        placeholder="Enter your address *"*/}
                                                    {/*        name="address"*/}
                                                    {/*        inputAutocompleteValue="off"*/}
                                                    {/*        defaultValue={this.state.UserProfileMdl.address ?? ""}*/}
                                                    {/*        onPlaceSelected={(place) => {*/}
                                                    {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                                    {/*        }}*/}
                                                    {/*        onChange={(place) => {*/}
                                                    {/*            this.setState(this.setState(Object.assign(this.state.UserProfileMdl, { address: place.formatted_address })));*/}
                                                    {/*        }}*/}
                                                    {/*        required*/}
                                                    {/*    />*/}
                                                    {/*    {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}*/}
                                                    {/*    <label htmlFor="address">Enter your Address</label>*/}
                                                    {/*</div>*/}
                                                    <p>Enter your address</p>
                                                    <div className="form-floating input-wrapper-payment mb-3">
                                                        <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_Address'] === true ? ' has-err' : '')} defaultValue={this.state.UserProfileMdl.address ?? ""} ref="address" id="address" aria-describedby="address" placeholder="Enter your Address *" required />
                                                        {this.state.Errors['IsEmpty_Address'] === true ? <div className="has-err-msg">{this.state.Errors['AddressMsg']}</div> : ''}
                                                        <label htmlFor="address">Enter your Address</label>
                                                    </div>

                                                    <div className="text-center d-flex justify-content-center">
                                                        <button type="button" id="btncloseAddressMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ChangeAddress()}>Submit</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { /*model for change Pending Payment*/}
                                <div className="modal fade" tabIndex="-1" id="modalPendingPayment" aria-labelledby="pendingPayment" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered pending-payment-modal">
                                        <div className="modal-content-PolicyInfo">
                                            <div className="modal-header justify-content-start pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/coin-glow.png"></img>
                                                <h5 className="modal-title text-center">Pending Payments</h5>
                                            </div>
                                                <div className="modal-body-newDesign">
                                                    <div>
                                                        { /*loader show befoure load contents*/}
                                                    </div>
                                                    <table className="table insured-table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Amount</th>
                                                                <th>Fee</th>
                                                                <th>Date</th>
                                                                <th>Confirmation Number</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className="table-bg">
                                                                <td>ACH Payment</td>
                                                                <td>$200.00</td>
                                                                <td>$5.00</td>
                                                                <td>8/25/2024</td>
                                                                <td>4822342</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                        </div>
                                    </div>
                                </div>
                                { /*model for change Policy Information*/}
                                <div className="modal fade" tabIndex="-1" id="modalPolicyInformation" aria-labelledby="policyInformation" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content-PolicyInfo">
                                            <div className="modal-header justify-content-start pt-4 border-0">
                                                <img className="modal-image" src="./assets/Content/images/map-pin-glow.png"></img>
                                                <h5 className="modal-title text-center">Policy Information</h5>
                                            </div>
                                            <div className="modal-body-newDesign">
                                                <div>
                                                    { /*loader show befoure load contents*/}
                                                </div>
                                                <table className="table insured-table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Policy Number</th>
                                                            <th>Company</th>
                                                            <th>Coverage</th>
                                                            <th>Amount</th>
                                                            <th>Effective Date</th>
                                                            <th>Expiration Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr >
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.policyNumber}</td>
                                                            <td id={currentPolicy.paymentId}>AIG Specialty Insurance Company</td>
                                                            <td id={currentPolicy.paymentId}>{/*{currentPolicy.coverageType}*/}GL </td>
                                                            <td id={currentPolicy.paymentId}>{currentPolicy.downPayment + (currentPolicy.minimumPaymentDue * currentPolicy.policyPeriod)}</td>
                                                            <td id={currentPolicy.paymentId}>{DateFormatShort(currentPolicy?.policyEffectiveDate)}</td>
                                                            <td id={currentPolicy.paymentId}>{DateFormatShort(currentPolicy?.expirationDate)}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                { /*model for confirm delete payment method */}
                                <div className="modal fade" id="confirmdeletemdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="staticBackdropLabel">Payment Method</h5>
                                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                                            </div>
                                            <div className="modal-body">
                                                Do you want to delete this payment method ? {this.state.DeleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                                            </div>
                                            <div className="modal-footer">
                                                <Button type="button" id="btncancle" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</Button>
                                                <Button type="button" id="btndelete" className="btn btn-primary" onClick={() => this._DeletePaymentMethod(this.state.CustomerDetailMdl.customerDetails.id)}>Delete</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    <SiteFooter />
                </div>
            }
           </>
        );
    }
}

export default AISSupportAccountOverview;