const CustomCurrencyFormat = (value) =>
    new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2
    }).format(value);

function DateFormat(dateString) {
    if (dateString === null || dateString?.trim() === "") {
        return null;
    }
    const options = Intl.DateTimeFormatOptions = {
        month: "short",
        year: "numeric",
        day: "numeric",
        //timeZone: 'CST'
    };
    return new Date(dateString).toLocaleDateString('en-US', options);
}

function DateFormatShort(dateString) {
    let date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}
const NumberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0
    }).format(value);

const formatPhoneNumber =(phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ')' + match[2] + '-' + match[3];
  }
  return null;
}

const DesimalFormat = (value) =>
    new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);


const Error = "Authorization has been denied for this request.";
const BaseUrl = "https://xgenpayments-new-design.azurewebsites.net";
//const BaseUrl = "https://localhost:44376";
const TestMerchandID = "6370"
const TestMerchandURL = "https://apiint.paymentsite.com/hostpay/HostPay"
const PublishableKey = "pk_test_51L0Ug4J4UsRbPTT2fMO1I8JBsUCGzxBX1I6SKRNBgmS8q6gShe3hXZ2FWhha2i1FYEHFY7bAAeiEvgxyOMjQODaU000UZypd7q";

export { Error, BaseUrl, CustomCurrencyFormat, DateFormat, DateFormatShort, NumberFormat, TestMerchandID, TestMerchandURL, DesimalFormat, PublishableKey, formatPhoneNumber };