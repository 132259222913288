import React, { Component } from 'react';
import { ChangePasswordByPassCode, VerifyLinkByRequestToken } from '../../../services/UserServices';
import { SiteFooter } from '../../layout/SiteFooter';
import validator from 'validator';
import { showError, showSuccess } from '../../../common/Common';
import $ from 'jquery';
class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Errors: {},
            queryParams: new URLSearchParams(window.location.search),
            RequestToken: null,
            ChangePassLoaderStatus: false
        }
    }
    componentWillMount() {
        this.setState({ RequestToken: null });
    }
    componentDidMount() {
        if (this.state.queryParams.has("RequestToken")) {
            this.setState({ RequestToken: this.state.queryParams.get("RequestToken") });
            this._VerifyLinkByRequestToken(this.state.queryParams.get("RequestToken"));
        }
        else {
            this.props.history.push('/');
        }
    }
    // Verify Link By Request Token
    _VerifyLinkByRequestToken = async (requestToken) => {
        VerifyLinkByRequestToken(requestToken).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ email: response.data.email })
                }
                else {
                    showError(response.message);
                    this.props.history.push('/');
                }
            }
        });
    }
    //form submit on enter key down
    _ChangePasswordByPassCode_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangePasswordByPassCode();
        };
    };
    //for change passwoed
    _ChangePasswordByPassCode = () => {
        let ChangePasswordMdl = {
            newPassword: this.refs.newpassword.value,
            confirmPassword: this.refs.confirmpassword.value,
            RequestToken: this.state.RequestToken

        }
        let status = this.ChangePasswordValidation(ChangePasswordMdl);
        if (status) {
            this.setState({ ChangePassLoaderStatus: true }); // show loader befour  change pass response form server
            ChangePasswordByPassCode(ChangePasswordMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        showSuccess(response.message);
                        $("#frmchangepassword").trigger("reset");
                        this.props.history.replace('/login');
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangePassLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    // validtion for change password validation
    ChangePasswordValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.newPassword === null || mdl.newPassword.trim() === "" || mdl.newPassword === undefined) {
            errors['IsEmpty_newPassword'] = true;
            errors['newPasswordMsg'] = "Required fields : New password";
            count++;
        }
        else {
            if (!validator.isLength(mdl.newPassword, 8)) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (mdl.confirmPassword === null || mdl.confirmPassword === undefined || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required fields : Confirm password";
            count++;
        }
        else {
            if (!validator.equals(mdl.newPassword, mdl.confirmPassword)) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
 
    render() {
        return (
            <>
                <main>
                    <div className="container form-space margin-top-passcode">
                        <div className=" justify-content-md-center">
                            <div className="">
                                <form id="frmchangepassword" className="step-form-wrapper position-relative pass-code-section form-selected" onKeyDown={this._ChangePasswordByPassCode_EnterButton }>
                                    <fieldset id="step-login" className="step-form" data-field="Login" data-next="Dashboard">
                                        <h2 className="mb-5 text-center">Change your password</h2>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input placeholder="Enter new password" className={(this.state.Errors['IsEmpty_newPassword'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} type="password" ref="newpassword" name="newpassword" autoComplete="off" id="new-password" required />
                                            {this.state.Errors['IsEmpty_newPassword'] === true ? <div className="has-err-msg">{this.state.Errors['newPasswordMsg']}</div> : ''}
                                            <label htmlFor="new-password">Enter new password</label>
                                        </div>
                                        <div className="form-floating input-wrapper-payment mx-auto mb-3">
                                            <input placeholder="Confirm password" className={(this.state.Errors['IsEmpty_confirmPassword'] === true ? ' has-err input-newDesign form-control' : 'input-newDesign form-control')} type="password" ref="confirmpassword" name="confirmpassword" autoComplete="off" id="confirm-password" required />
                                            {this.state.Errors['IsEmpty_confirmPassword'] === true ? <div className="has-err-msg">{this.state.Errors['confirmPasswordMsg']}</div> : ''}
                                            <label htmlFor="confirm-password">Confirm password</label>
                                        </div>                              
                                        <div className="d-flex justify-content-center mt-4 mb-3">
                                             <div className="d-flex ps-3">
                                                <button type="button" id="Continue" className="btn btn-newDesign make-payment-btn" onClick={() => this._ChangePasswordByPassCode()}>
                                                    <span className="label">Submit</span>
                                                    <svg className="icon-arrow after">
                                                        <use xlinkHref="#arrow"></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                        
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow-left" viewBox="0 0 35 15">
                                            <title>Arrow Left</title>
                                            <path id="Layer" className="s0" d="m7.73 9.14l2.17 2.17l-2.83 2.83l-7.07-7.07l7.07-7.07l2.83 2.83l-2.31 2.31l27.31 0v4l-27.17 0z"></path>
                                        </symbol>
                                    </defs>
                                </svg>
                                <svg style={{ display: "none" }}>
                                    <defs>
                                        <symbol id="arrow" viewBox="0 0 35 15">
                                            <title>Arrow</title>
                                            <path d="M27.172 5L25 2.828 27.828 0 34.9 7.071l-7.07 7.071L25 11.314 27.314 9H0V5h27.172z " />
                                        </symbol>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                </main>
                <SiteFooter /> {/*this call for site footer */}
             
            </>
        );
    }
}
export default ChangePassword;