function refreshCache() {
    if (caches) {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    }
}

function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}


function clearStoragesAndCookies() {
    localStorage.clear();
    sessionStorage.clear();
    //deleteAllCookies();
}

async function checkIfVersionChange(appVersion) {
    let latestVersion;
    await fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
            latestVersion = meta.version;
            const currentVersion = appVersion;
            const old_version = localStorage.getItem("current_version");
            if (old_version !== currentVersion) {
                clearStoragesAndCookies();
                location.reload();
            }
        }).then(() => {
            localStorage.setItem("current_version", latestVersion);
        });
    refreshCache();
}

export { checkIfVersionChange, refreshCache, deleteAllCookies };