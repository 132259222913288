import React, { Component } from 'react';
import validator from 'validator';
import { NavLink } from 'react-router-dom';
import { InternalLayoutHeader } from "../../layout/InternalLayoutHeader";
import { SiteFooter } from "../../layout/SiteFooter";
import { LoaderContents } from "../../layout/LoaderContents";
import { ChangePassword, AddManageText, GetActivation, GetActivationByUserId, GetUserProfile } from '../../../services/UserServices';
import { AddPaymentMethod, GetAgentPaymentProfileByUserId, DeleteAgentPaymentMethod, GetFppAgentPaymentProfileByUserId, DeleteFppAgentPaymentMethod } from '../../../services/PaymentAPIServices';
import { showError, showSuccess, authHeader, Button  } from '../../../common/Common';
import $ from 'jquery';
import { loadStripe } from "@stripe/stripe-js";
import ACHOnBoard from '../Common/ACHOnBoard';
import { Elements } from "@stripe/react-stripe-js";
import { PublishableKey } from "../../../common/Setting";

const stripePromise = loadStripe(PublishableKey);
class AgentMySettings extends Component {
    //this is for agent my setting module
    constructor(props) {
        super(props);
        this._IsTextActive = this._IsTextActive.bind(this);
        this.state = {
            Errors: {},
            ChangePassLoaderStatus: false,
            ChangeAddressLoaderStatus: false,
            LoaderStatus: false,
            GetPaymentProfileData: [],
            DeleteLoader: false,
            IsTextActive: true,
            UserId: ''
          
        };
  
    }
    componentDidMount() {
        this._GetAgentPaymentProfileByUserId();
        this._GetUserProfile();
    }

    //get payment profile
    _GetAgentPaymentProfileByUserId = () => {
        this.setState({ LoaderStatus: true }); // show loader
        this.setState({ GetPaymentProfileData: [] });
        if (JSON.parse(localStorage.getItem("SubDomain"))?.PaymentProcessId == 2) {
            GetFppAgentPaymentProfileByUserId().then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        if (response.data !== null) {
                            this.setState({ GetPaymentProfileData: response.data });
                        }
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
        else {
            GetAgentPaymentProfileByUserId().then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        if (response.data !== null) {
                            this.setState({ GetPaymentProfileData: response.data });
                        }
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }
    //form submit on enter key down (change password)
    _ChangePassword_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._ChangePassword();
        };
    };
     //form submit on enter key down (change payment info auto pay)
    _AddPaymentMethod_EnterButton = (event) => {
        let keyCode = event.which || event.keyCode;
        if (keyCode === 13) {
            this._AddPaymentMethod();
        };
    };
    _DeletePaymentMethod = () => {
        this.setState({ DeleteLoader: true }); // show loader
        if (JSON.parse(localStorage.getItem("SubDomain"))?.PaymentProcessId == 2) {
            DeleteFppAgentPaymentMethod().then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#confirmdeletemdl').modal('hide');
                        showSuccess(response.message);
                        this.setState({ GetPaymentProfileData: [] });
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ DeleteLoader: false }); // hide loader
                }
            });
        }
        else {
            DeleteAgentPaymentMethod().then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        window.$('#confirmdeletemdl').modal('hide');
                        showSuccess(response.message);
                        this.setState({ GetPaymentProfileData: [] });
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ DeleteLoader: false }); // hide loader
                }
            });
        }
       
    }
    // change payment info
    _AddPaymentMethod = () => {
       let  AddPaymentProfileMdl =
        {
           "paymentTypeId": 1,
           "FullName": authHeader().FullName,
           "Email": authHeader().UserName,
           "achAccountHolderName": this.refs.achAccountHolderName.value,
           "achRoutingNumber": this.refs.achRoutingNumber.value,
           "achAccountNumber": this.refs.achAccountNumber.value
        }
        let status = this.PaymentInfoValidation(AddPaymentProfileMdl);
        if (status) {
            this.setState({ LoaderStatus: true }); // show loader
            AddPaymentMethod(AddPaymentProfileMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                       // window.$('#modalAddPaymentMethod').modal('hide');
                        showSuccess(response.message);
                        this._GetAgentPaymentProfileByUserId();
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }

    _GetUserProfile = async () => {
        GetUserProfile().then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ UserId: response.data.id });
                        this._GetActivationByUserId();
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });

    }

    _GetActivationByUserId = async () => {
        GetActivationByUserId(this.state.UserId).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ IsTextActive: response.data.isTextActive });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }

    _IsTextActive = (event) => {
        this.setState({ IsTextActive: event.target.checked }, () => {
            this._GetActivation();
        })
    }

    _GetActivation = () => {
        let userInfoMdl = {
            UserId: this.state.UserId,
            IsTextActive: this.state.IsTextActive
        }

        GetActivation(userInfoMdl).then(data => {
            let response = data;
            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data != null) {
                        this.setState({ mdl: response.data.userInfoMdl.IsTextActive });
                        this.setState({ mdl: response.data.userInfoMdl.UserId });
                    }
                }
                else {
                    showError(response.message);
                }
            }
        });
    }
    //validation for change payment info
    PaymentInfoValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.achAccountHolderName === null || mdl.achAccountHolderName === undefined || mdl.achAccountHolderName.trim() === "") {
                errors['IsEmpty_achAccountHolderName'] = true;
                errors['achAccountHolderNameMsg'] = "Required field: Account Holder Name";
                count++;
            }
        if (mdl.achRoutingNumber === null || mdl.achRoutingNumber === undefined || mdl.achRoutingNumber.trim() === "") {
                errors['IsEmpty_achRoutingNumber'] = true;
                errors['achRoutingNumberMsg'] = "Required field: Routing Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achRoutingNumber)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Not a valid number";
                    count++;
                }
                else if (!validator.isLength(mdl.achRoutingNumber, 9, 9)) {
                    errors['IsEmpty_achRoutingNumber'] = true;
                    errors['achRoutingNumberMsg'] = "Should contain at least 9 digit";
                    count++;
                }
            }
        if (mdl.achAccountNumber === null || mdl.achAccountNumber === undefined || mdl.achAccountNumber.trim() === "") {
                errors['IsEmpty_achAccountNumber'] = true;
                errors['achAccountNumberMsg'] = "Required field: Account Number";
                count++;
            }
            else {
                if (!validator.isNumeric(mdl.achAccountNumber)) {
                    errors['IsEmpty_achAccountNumber'] = true;
                    errors['achAccountNumberMsg'] = "Not a valid number";
                    count++;
                }
            }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }
    //for change passwoed
    _ChangePassword = () => {
        let ChangePasswordMdl = {
            currentPassword: this.refs.currentPassword.value,
            newPassword: this.refs.newPassword.value,
            confirmPassword: this.refs.confirmPassword.value,
        }
        let status = this.ChangePasswordValidation(ChangePasswordMdl);
        if (status) {
            this.setState({ ChangePassLoaderStatus: true }); // show loader befour  change pass response form server
            ChangePassword(ChangePasswordMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {
                        document.getElementById('btncloseMdl').click();
                        showSuccess(response.message);
                        //document.getElementById("frmchangepassword").reset();
                        $("#frmchangepassword").trigger("reset");
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ ChangePassLoaderStatus: false }); // hide loader
                }
            });
        }
    }
    // validtion for change password validation
    ChangePasswordValidation = (mdl) => {
        let errors = {};
        let count = 0;
        if (mdl.currentPassword === null || mdl.currentPassword.trim() === "" || mdl.currentPassword === undefined) {
            errors['IsEmpty_currentPassword'] = true;
            errors['currentPasswordMsg'] = "Required fields : Current password";
            count++;
        }
        if (mdl.newPassword === null || mdl.newPassword.trim() === "" || mdl.newPassword === undefined) {
            errors['IsEmpty_newPassword'] = true;
            errors['newPasswordMsg'] = "Required fields : New password";
            count++;
        }
        else {
            if (!validator.isLength(mdl.newPassword, 8)) {
                errors['IsEmpty_newPassword'] = true;
                errors['newPasswordMsg'] = "Password must at least 8 characters long";
                count++;
            }
        }
        if (mdl.confirmPassword === null || mdl.confirmPassword === undefined || mdl.confirmPassword.trim() === "") {
            errors['IsEmpty_confirmPassword'] = true;
            errors['confirmPasswordMsg'] = "Required fields : Confirm password";
            count++;
        }
        else {
            if (!validator.equals(mdl.newPassword, mdl.confirmPassword)) {
                errors['IsEmpty_confirmPassword'] = true;
                errors['confirmPasswordMsg'] = "Passwords doesn't match : Confirm password";
                count++;
            }
        }
        this.setState({ Errors: errors });
        if (count === 0) {
            return true;
        }
    }

    _AddManageText = () => {
        let ManageTextMdl =
        {
            "Id": 1,
            userInfoMdl: {
                UserId: 0
            },
            "Status": null,
            "PhoneNumber": this.refs.PhoneNumber.value
        }
        let status = this.ManageTextValidation(AddManageTextMdl);
        if (status) {
            this.setState({ LoaderStatus: true }); // show loader
            AddManageText(ManageTextMdl).then(data => {
                let response = data;
                if (response.message !== Error) {
                    if (response.status === true) {

                        showSuccess(response.message);
                    }
                    else {
                        showError(response.message);
                    }
                    this.setState({ LoaderStatus: false }); // hide loader
                }
            });
        }
    }

    //validation for manage ManageText
    ManageTextValidation = (ManageTextMdl) => {
        let errors = {};
        let count = 0;
        if (ManageTextMdl.PhoneNumber === null || ManageTextMdl.PhoneNumber === undefined || ManageTextMdl.PhoneNumber.trim() === "") {
            errors['IsEmpty_PhoneNumber'] = true;
            errors['PhoneNumberEmptyMsg'] = "Please fill the required fields : PhoneNumber Number";
            count++;
        }
        else {
            let pattern = new RegExp(/^((\+0?1\s)?)\(?\d{3}\)?[\s.\s]\d{3}[\s.-]\d{4}$/);

            if (!pattern.test(ManageTextMdl.PhoneNumber)) {

                errors['IsEmpty_PhoneNumber'] = true;
                errors['PhoneNumberEmptyMsg'] = "Please enter valid PhoneNumber number";
                count++;

            }
        }
    }

    //check number only
    checkNumber = (event) => {
        let errors = {};
        let value = event.target.value;
        let fld = event.target.name;
        if (!validator.isNumeric(value)) {
            errors['IsEmpty_' + fld] = true;
            errors[fld + 'Msg'] = "Not a valid number";
        }
        this.setState(Object.assign(this.state.Errors, errors));
    }
 
    render() {
        const { GetPaymentProfileData } = this.state;
        return (
            <>
                <div className="main-wrapper-newDesign w-100">
                    <InternalLayoutHeader />
                    <div className="content-main p-lg-4 p-3">
                        <h1 className="h3 mb-3 fw-normal"><strong>My Settings</strong></h1>
                        <div className="row">
                            {this.props.props !== true &&
                                <>
                                <div className="overview-card settings-page-card">
                                    <div className="settings-page-items">
                                        <p className="fw-bold">Security</p>
                                        <hr/>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                                <p>Password</p>
                                                <p className="label-overview-newDesign">**********</p>
                                            </div>
                                            <a href="#modalChangePassword" data-bs-toggle="modal">
                                                <button type="button" className="btn-newDesign settings-button">Change Password</button>
                                            </a>
                                        </div>
                                        <div className="settings-item">
                                            <div className="settings-label">
                                                <p>AutoPay</p>
                                            <p className="label-overview-newDesign">Manage Desposit Details</p>
                                            </div>
                                            <a href="#modalAddPaymentMethod" data-bs-toggle="modal"> 
                                                <button type="button" className="btn-newDesign settings-button">Update Autopay</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </>
                            }

                        </div>
                    </div>
                    <SiteFooter />  {/*this call for site footer */}
                </div>
                { /*model for change password*/}
                <div className="modal fade" tabIndex="-1" id="modalChangePassword" aria-labelledby="chagePassword" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-start pt-4 border-0">
                                <img className="modal-image" src="./assets/Content/images/key-glow.png"></img>
                                <h5 className="modal-title text-center">Change Password</h5>
                            </div>
                            <div className="modal-body-newDesign">
                                <div>
                                    { /*loader show befoure load contents*/}
                                    {this.state.ChangePassLoaderStatus && <LoaderContents />}
                                </div>
                                <form className="w-75 m-auto" id="frmchangepassword" onKeyDown={this._ChangePassword_EnterButton}>
                                    <p className="modal-header-newDesign">Current Password</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="password" className={(this.state.Errors['IsEmpty_currentPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="currentPassword" id="currentPassword" aria-describedby="currentPassword" placeholder="Enter your current password *" required />
                                        {this.state.Errors['IsEmpty_currentPassword'] === true ? <div className="has-err-msg">{this.state.Errors['currentPasswordMsg']}</div> : ''}
                                        <label htmlFor="currentPassword">Enter your current password</label>
                                    </div>
                                    <p>New Password</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="password" className={(this.state.Errors['IsEmpty_newPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="newPassword" id="newPassword" aria-describedby="newPassword" placeholder="Enter your new password *" required />
                                        {this.state.Errors['IsEmpty_newPassword'] === true ? <div className="has-err-msg">{this.state.Errors['newPasswordMsg']}</div> : ''}
                                        <label htmlFor="newPassword">Enter your new password</label>
                                    </div>
                                    <p>Confirm Password</p>
                                    <div className="form-floating input-wrapper-payment mb-3">
                                        <input type="password" className={(this.state.Errors['IsEmpty_confirmPassword'] === true ? ' has-err form-control input-newDesign' : 'form-control input-newDesign')} ref="confirmPassword" id="confirmPassword" name="confirmPassword" placeholder="Confirm password *" required />
                                        {this.state.Errors['IsEmpty_confirmPassword'] === true ? <div className="has-err-msg">{this.state.Errors['confirmPasswordMsg']}</div> : ''}
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                    </div>
                                    <div className="text-center d-flex justify-content-center">
                                        <button type="button" id="btncloseMdl" data-bs-dismiss="modal" className="btn btn-newDesign-reverse me-2">Close</button>
                                        <button type="button" className="btn btn-newDesign" onClick={() => this._ChangePassword()}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>         
                { /*model for change payment method*/}
                <div className="modal fade" tabIndex="-1" id="modalAddPaymentMethod" aria-labelledby="modalAddPaymentMethod" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">Add ACH account details</h5>
                                <button type="button" id="btnclosePaymentMethodMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.LoaderStatus && <LoaderContents />}
                                </div>
                                {GetPaymentProfileData.length <= 0 ?
                                    //<form className="w-75 m-auto" id="frmchangePaymentMethod" onKeyDown={this._AddPaymentMethod_EnterButton}>

                                    //    <div className='ACH-Section' id='ACH-Section'>
                                    //        <div className="form-floating input-wrapper-payment mb-3">
                                    //            <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_achAccountHolderName'] === true ? ' has-err' : '')} ref="achAccountHolderName" id="AccountHolderName" name="achAccountHolderName" placeholder="Account holder name*" defaultValue={GetPaymentProfileData.achAccountHolderName ?? ""} required />
                                    //            {this.state.Errors['IsEmpty_achAccountHolderName'] === true ? <span className="has-err-msg">{this.state.Errors['achAccountHolderNameMsg']}</span> : ''}
                                    //            <label htmlFor="AccountHolderName">Account Holder Name</label>
                                    //        </div>
                                    //        <div className="form-floating input-wrapper-payment mb-3">
                                    //            <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_achRoutingNumber'] === true ? ' has-err' : '')} ref="achRoutingNumber" id="RoutingNumber" name="achRoutingNumber" placeholder="9 digit routing number*" maxLength='9' onInput={this.checkNumber} defaultValue={GetPaymentProfileData.achRoutingNumber ?? ""} required />
                                    //            {this.state.Errors['IsEmpty_achRoutingNumber'] === true ? <span className="has-err-msg">{this.state.Errors['achRoutingNumberMsg']}</span> : ''}
                                    //            <label htmlFor="RoutingNumber">9 Digit Routing Number </label>
                                    //        </div>
                                    //        <div className="form-floating  input-wrapper-payment mb-3">
                                    //            <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_achAccountNumber'] === true ? ' has-err' : '')} ref="achAccountNumber" id="AccountNumber" name="achAccountNumber" placeholder="Account number*" onInput={this.checkNumber} defaultValue={GetPaymentProfileData.achAccountNumber ?? ""} required />
                                    //            {this.state.Errors['IsEmpty_achAccountNumber'] === true ? <span className="has-err-msg">{this.state.Errors['achAccountNumberMsg']}</span> : ''}
                                    //            <label htmlFor="AccountNumber">Account Number</label>
                                    //        </div>
                                    //    </div>
                                    //    <div className="text-center">
                                    //        <button type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => this._AddPaymentMethod()}>Submit</button>
                                    //    </div>
                                    //</form>
                                    <Elements stripe={stripePromise}>
                                        <ACHOnBoard />
                                    </Elements>
                                    :
                                    <div>
                                    <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Account Holder Name</th>
                                                <th>Account Number</th>
                                                <th>Routing Number</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                <tr>
                                                <td>{GetPaymentProfileData.achAccountHolderName ?? ''}</td>
                                                    <td>{GetPaymentProfileData.achAccountNumber ? '********' + GetPaymentProfileData.achAccountNumber : ''}</td>
                                                    <td>{GetPaymentProfileData.achRoutingNumber ?? ''}</td>
                                                    <td><NavLink to="#confirmdeletemdl" data-bs-toggle="modal">Delete</NavLink></td>
                                                </tr>
                                        </tbody>
                                        </table>
                                        
                                        </div>
                                        <p className="text-danger pt-4">Note*: This will be the account we deposit commissions to. </p>
                                        <p className="text-danger">Note*: Please delete your current payment method in order to add a new one. </p>
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for manage text*/}
                <div className="modal fade" tabIndex="-1" id="modalManageText" aria-labelledby="modalManageText" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header justify-content-center pt-4 border-0">
                                <h5 className="modal-title text-center">Manage Promotional Messages</h5>
                                <button type="button" id="btncloseManageTextMdl" className="btn-close position-absolute" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="pt20px">
                                    { /*loader show befoure load contents*/}
                                    {this.state.LoaderStatus && <LoaderContents />}
                                </div>

                                <form className="w-75 m-auto" id="frmchangeManageText" onKeyDown={this._AddManageText_EnterButton}>

                                    <div className='ACH-Section' id='ACH-Section'>
                                        <div className="form-floating input-wrapper-payment mb-3">
                                            <input type="text" className={'form-control' + (this.state.Errors['IsEmpty_PhoneNumber'] === true ? ' has-err' : '')} ref="PhoneNumber" id="PhoneNumber" name="PhoneNumber" placeholder="PhoneNumber*" maxLength="14" required />
                                            {this.state.Errors['IsEmpty_PhoneNumber'] === true ? <div className="has-err-msg">{this.state.Errors['PhoneNumberEmptyMsg']}</div> : ''}
                                            <label htmlFor="PhoneNumber">Phone Number</label>
                                        </div>
                                        <div className="form-floating input-wrapper-payment mb-3">
                                            <input type="text" className="form-control" ref="FullName" id="FullName" aria-describedby="FullName" placeholder="FullName" />
                                            <label htmlFor="FullName">Full Name</label>
                                        </div>
                                        <div className="mx-auto mb-12" >
                                            <div className='input-wrapper-radio'>
                                                <div className="col-12 promotional-modal-check">
                                                    <input type="checkbox" id="radio-Active" name="IsActive" checked={this.state.IsActive} required className="form-check-input" onChange={this.IsActive} />
                                                    <label className="form-check-label">
                                                        You agree to recieve automated promotional messages. You
                                                        also agree to the <a href="#">  Terms of Service</a> and <a href="#"> Privacy Policy</a>.
                                                        This agreement isn't a condition of any purchase. 4 Msgs/Month.
                                                        Msg & Data rates may apply. Reply STOP to end or HELP fo help.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button type="button" className="btn btn-primary btn-lg px-5 submit-btn" onClick={() => this._AddManageText()}>Subscribe Now</Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                { /*model for confirm delete payment method */}
                <div className="modal fade" id="confirmdeletemdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Payment Method</h5>
                                {/*    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
                            </div>
                            <div className="modal-body">
                                Do you want to delete this payment method ? {this.state.DeleteLoader && <i className="fa fa-refresh fa-spin fa-1x"></i>}
                            </div>
                            <div className="modal-footer">
                                <Button type="button" id="btncancle" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</Button>
                                <Button type="button" id="btndelete" className="btn btn-primary" onClick={() => this._DeletePaymentMethod()}>Delete</Button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </>
        );
    }
}
export default AgentMySettings;