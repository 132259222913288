import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
//start layouts out import
import ExternalLayout from "./components/layout/ExternalLayout";
import InternalLayout from "./components/layout/InternalLayout";
//start pages import for common
import Login from "./components/pages/Common/Login";
import PrivacyPolicy from "./components/pages/Common/PrivacyPolicy";
import EnterPassCode from "./components/pages/Common/EnterPassCode";
import ChangePassword from "./components/pages/Common/ChangePassword"
import MakePayment from "./components/pages/Common/MakePayment";
import PaymentStatus from "./components/pages/Common/PaymentStatus";
import PaymentWidget from "./components/pages/Common/PaymentWidget";
import PaymentWidgetStatus from './components/pages/Common/PaymentWidgetStatus';
import UnauthorizedAccess from './components/pages/Common/UnauthorizedAccess';
//test
import DocuSignComplete from "./components/pages/Common/DocuSignComplete";
//end page import
// this is common funtion and conts list
import { authHeader, Logout, subDomain } from './common/Common';
import { pageComponents } from './common/PageComponents';
import { DesignLayout } from './common/Enum';
import AuthVerify from './common/AuthVerify';
import { GetAccessModulesByRoleId, GetExternalAccessModules } from "./services/AccessModulesServices";
import { NavBar } from './common/NavBar';
//Imports for busting cache
import { checkIfVersionChange } from './CacheBuster.js';
import packageJson from '../package.json';
global.appVersion = packageJson.version;
/*global google*/
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GetAllPartnersByName } from "./services/PartnerServices";
import TermsOfUse from './components/pages/Common/TermsOfUse';
import FppPaymentStatus from "./components/pages/Common/FppPaymentStatus";
import { PublishableKey } from "../src/common/Setting";
import ViewBillingStatements from "./components/pages/Insured/ViewBillingStatements";

const stripePromise = loadStripe(PublishableKey);
export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            UserDetails: authHeader(),
            SubDomain: subDomain(),
            userAccessModules: [],
            externalAccessModules: [],
            routeSubdomain: {
                url: null
            },
            LoggedoutResults: this._GetAllPartnersByName(window.location.pathname.replace(/^\/([^\/]*).*$/, '$1'))
        };
    }
    logOut() {
        Logout();
    }

    componentDidMount() {
        localStorage.removeItem('LoggedOutDetails');
        const IsLoggedIn = JSON.parse(localStorage.getItem('IsLoggedIn'));
        var url = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
        if (IsLoggedIn === null) {
            this._GetAllPartnersByName(url);
        }

        if (this.state.UserDetails != null) {
            this.onChangeValue(this.state.UserDetails.Authorization);
            let RoleIds = this.state.UserDetails.UserRoleIds;
            if (RoleIds != null) {
                this._GetAccessModulesByRoleId(RoleIds);
            }
        }
        else {
            this.onChangeValue();
            this._GetExternalAccessModules();
        }
    }

    _GetAllPartnersByName = (pathname) => {
        GetAllPartnersByName(pathname).then(res => {
            let response = res;

            if (response.message !== Error) {
                if (response.status === true) {
                    if (response.data !== null) {
                        localStorage.setItem("LoggedOutDetails", JSON.stringify({
                            LoggedOutSubDomainId: response.data.id, LoggedOutLogo: response.data.logo, LoggedOutColor: response.data.color, LoggedOutHoverFocus: response.data.hoverFocus,
                            LoggedOutSubDomain: response.data.brandName, LoggedOutSubDomainUrl: response.data.url, LoggedOutLabel1: response.data.label1, LoggedOutLabel2: response.data.label2,
                            isAISPF: response.data.isAISPF, isAISDB: response.data.isAISDB, isBDSecurePF: response.data.isBDSecurePF, isFinanceProPF: response.data.isFinanceProPF
                        }));
                        this.setState({ routeSubdomain: response.data });

                    }
                    else {
                        localStorage.setItem("LoggedOutDetails", JSON.stringify({
                            LoggedOutSubDomainId: 1, LoggedOutLogo: "assets/Content/images/ais-logo.png", LoggedOutColor: "#008592", LoggedOutHoverFocus: "",
                            LoggedOutSubDomain: "Xgen Company", LoggedOutSubDomainUrl: "Xgen", LoggedOutLabel1: "Down Payment", LoggedOutLabel2: "Monthly Payment"
                        }));
                        this.setState({ routeSubdomain: null });

                    }
                }
            }
        });
    }

    onChangeValue = (Token = null) => {
        if (Token !== null && Token !== "") {
            if (window.location.pathname === "/login") {
                window.open('/dashboard', '_self');
            }
        }
    }
    _GetAccessModulesByRoleId = (RoleId) => {
        GetAccessModulesByRoleId(RoleId).then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ userAccessModules: response.data });
                }
            }
        });
    }

    _GetExternalAccessModules = () => {
        GetExternalAccessModules().then(res => {
            let response = res;
            if (response.message !== Error) {
                if (response.status === true) {
                    this.setState({ externalAccessModules: response.data });
                }
            }
        });
    }

    render() {
        const { userAccessModules, externalAccessModules } = this.state;
        let url = (this.state.SubDomain === null || this.state.SubDomain.SubDomain === 'Xgen') ? "" : "/" + this.state.SubDomain.SubDomain;
        if (this.state.routeSubdomain !== null && this.state.routeSubdomain.url !== null) {
            document.title = (this.state.routeSubdomain.url?.toLowerCase() || '') === '' ? 'Xgen Billing' : this.state.routeSubdomain.url.charAt(0).toUpperCase() + this.state.routeSubdomain.url.slice(1).toLowerCase() + ' Billing';
            document.body.style.setProperty("font-family", ((this.state.routeSubdomain.fontFamily === '' || this.state.routeSubdomain.fontFamily === null) ? "'DM Sans', sans-serif" : this.state.routeSubdomain.fontFamily), "important");

        }
        if (this.state.UserDetails != null) {
            document.body.style.setProperty("font-family", ((this.state.SubDomain.FontFamily === '' || this.state.SubDomain.FontFamily === null) ? "'DM Sans', sans-serif" : this.state.SubDomain.FontFamily), "important");
        }

        checkIfVersionChange(global.appVersion);
        return (
            <Elements stripe={stripePromise}>

                <Switch>
                    {(userAccessModules !== null && userAccessModules.length !== 0) &&
                        <>

                            <div className="wrapper d-flex">
                                <NavBar />
                                {
                                    userAccessModules.map((route, index) => (
                                        route.designLayout === DesignLayout.InternalLayout &&
                                        <>
                                            <InternalLayout key={index} exact path={url + "" + route.moduleURL} props={route.canView} component={pageComponents[route.moduleName]} />
                                        </>
                                    ))
                                }
                                {
                                    userAccessModules.map((route, index) => (
                                        route.designLayout === DesignLayout.Route &&
                                        <>
                                            <Route key={index} exact path={url + "" + route.moduleURL} component={pageComponents[route.moduleName]} />
                                        </>

                                    ))
                                }
                                {
                                    <>
                                        <Route exact path={"/docusign-complete"} component={DocuSignComplete} />
                                    </>
                                }

                            </div>

                        </>

                    }

                </Switch>
                {this.state.UserDetails === null &&
                    <Switch>

                        <Route exact path="/" component={Login} />
                        <Route path={"/login"} component={Login} />
                        <Route path="/view-billing-statements" component={ViewBillingStatements} />
                        <Route path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/login"} component={Login} />
                        <Route exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={Login} />
                        <ExternalLayout path="/enter-passcode" component={EnterPassCode} />
                        <ExternalLayout path="/change-password" component={ChangePassword} />
                        <ExternalLayout path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/make-payment"} component={MakePayment} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={MakePayment} />
                        <Route path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/process-payment"} component={PaymentWidget} />
                        <Route exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={PaymentWidget} />
                        <Route path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/payment-widget-status"} component={PaymentWidgetStatus} />
                        <Route exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={PaymentWidgetStatus} />
                        {/*<Route exact path="/payment-widget-status" component={PaymentWidgetStatus} />*/}
                        <ExternalLayout exact path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/privacypolicy"} component={PrivacyPolicy} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={PrivacyPolicy} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url) +"/view-billing-statements"} component={ViewBillingStatements} />
                        <ExternalLayout exact path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/termsofuse"} component={TermsOfUse} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={TermsOfUse} />
                        <ExternalLayout exact path={(this.state.routeSubdomain == null ? "" : "/" + this.state.routeSubdomain.url) + "/payment-status"} component={PaymentStatus} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={PaymentStatus} />
                        <ExternalLayout exact path="/docusign-complete" component={DocuSignComplete} />
                        <ExternalLayout exact path={(this.state.routeSubdomain === null ? "" : "/" + this.state.routeSubdomain.url) + "/fpp-payment-status"} component={FppPaymentStatus} />
                        <ExternalLayout exact path={"/" + (this.state.routeSubdomain == null ? "Xgen" : this.state.routeSubdomain.url)} component={FppPaymentStatus} />
                        {(externalAccessModules !== null && externalAccessModules.length !== 0) &&
                            <>
                                {
                                    externalAccessModules.map((extroute) => (
                                        extroute.normalroute === 3 &&
                                        <>
                                            <ExternalLayout path='*' exact={true} component={UnauthorizedAccess} />
                                        </>

                                    ))

                                }
                            </>
                        }
                    </Switch>
                }
                <AuthVerify logOut={this.logOut} />
                <div className="loader-overlay">
                    <div className="loader">
                        <span className="dot"></span>
                        <div className="dots">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </Elements>
        );
    }
}
